import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RichText from '../Core/RichText';
import { Api } from '../Utils/Api';
import { formatDay } from '../Utils/Datetime';

const { Blog: BlogApi } = Api;

const NotFound = () => (
  <div>
    <h1>Hmm.. Looks like this page cannot be found.</h1>
    <p>
      <Link to="/blog">Back to blog</Link>
    </p>
  </div>
);

const DetailView = ({ item }) => {
  if (!item) return <NotFound />;

  const { thumb, publish_date, title, body } = item;

  return (
    <div className="container">
      {/* <div className="blog-nav">
        <Link to="/blog">
          <span className="fa fa-angle-left" /> The Blog
        </Link>
      </div> */}
      <div className="detail-view">
        <div className="image-wrapper">
          <div className="thumb mb-4">
            <img src={thumb} alt={title} />
          </div>
        </div>
        <div className="details">
          <div className="publish-date font-sans text-sm">
            {formatDay(publish_date)}
          </div>
          <h1 className="mb-4 text-xl">{title}</h1>
          <RichText html={body} />
        </div>
      </div>
    </div>
  );
};

const ListItem = ({ item }) => {
  const { thumb, publish_date, title, summary, url } = item;

  return (
    <div className="list-item">
      <div className="thumb">
        <Link to={url}>
          <img src={thumb} alt={title} />
        </Link>
      </div>
      <div className="details">
        <div className="publish-date">{formatDay(publish_date)}</div>
        <h3 className="title">
          <Link to={url}>{title}</Link>
        </h3>
        <div className="summary">
          <p>{summary}</p>
        </div>
      </div>
    </div>
  );
};

const List = ({ slug }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    BlogApi.list().then((response) => {
      setItems(response);
      setLoading(false);
    });
  }, [slug]);

  useEffect(() => {
    setSelected(slug ? items.find((item) => item.slug === slug) : null);
  }, [slug]);

  if (loading) return <div />;

  if (selected) return <DetailView item={selected} />;

  return (
    <>
      {/* <div>
        <h1 className="mb-4 text-4xl">The Blog</h1>
      </div> */}
      <div className="list">
        {items?.map((item) => (
          <ListItem key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};

const Blog = ({ match: { params } }) => {
  const { slug } = params;

  return (
    <div className="blog-view">
      <div className="blog container">
        <List slug={slug} />
      </div>
    </div>
  );
};

export default Blog;
