import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { environment } from './config';

Sentry.init({
  dsn:
    'https://09eebad472c14aa4ae75f626a2d5e070@o507716.ingest.sentry.io/5599216',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
