import React, { Component } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';

const { Permissions } = Plugins;
const { query: queryPermissions } = Permissions;
const { platform } = Capacitor;

const { Consumer, Provider } = React.createContext();

export const AppConsumer = ({ children }) => <Consumer>{children}</Consumer>;

export class AppProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      messages: [],
      timers: [],
      provinces: [],
      pushNotificationsPermission: undefined,
    };
  }

  componentDidMount() {
    if (platform === 'ios') {
      queryPermissions({ name: 'notifications' }).then(({ state }) => {
        this.setState({ pushNotificationsPermission: state });
      });
    }
  }

  addMessage = (message) => {
    const { messages, timers } = this.state;
    messages.push(message);
    const nonDuplicateMessages = Array.from(new Set(messages));

    // When there were duplicates, the nonDuplicateErrors array
    // will have less elements in the array. Do not add a timer then.
    if (nonDuplicateMessages.length === messages.length) {
      const timer = setTimeout(() => this.removeMessage(0), 15000);
      timers.push(timer);
    }

    // avoid duplicate error messages. For instance uploading multiple
    // files that return the same message, it is unecessary to repeat
    // ES6 Short-hand unique array values.
    this.setState({
      messages: nonDuplicateMessages,
      timers,
    });
  };

  removeMessage = (index) => {
    const { messages, timers } = this.state;
    timers.shift();

    this.setState({
      messages: messages.filter((message, i) => i !== index),
      timers,
    });
  };

  clearMessages = () => {
    this.setState({ messages: [] });
  };

  addError = (message) => {
    const { errors, timers } = this.state;
    errors.push(message);
    const nonDuplicateErrors = Array.from(new Set(errors));

    // When there were duplicates, the nonDuplicateErrors array
    // will have less elements in the array. Do not add a timer then.
    if (nonDuplicateErrors.length === errors.length) {
      const timer = setTimeout(() => this.removeError(0), 15000);
      timers.push(timer);
    }

    // avoid duplicate error messages. For instance uploading multiple
    // files that return the same message, it is unecessary to repeat
    // ES6 Short-hand unique array values.
    this.setState({
      errors: nonDuplicateErrors,
      timers,
    });
  };

  removeError = (index) => {
    const { errors, timers } = this.state;
    timers.shift();

    this.setState({
      errors: errors.filter((error, i) => i !== index),
      timers,
    });
  };

  setErrors = (errors) => {
    this.setState({ errors });
  };

  clearErrors = () => {
    this.setState({ errors: [] });
  };

  render() {
    const { pushNotificationsPermission } = this.state;
    const { children } = this.props;
    return (
      <Provider
        value={{
          state: this.state,
          pushNotificationsPermission,
          addMessage: this.addMessage,
          removeMessage: this.removeMessage,
          clearMessages: this.clearMessages,
          addError: this.addError,
          removeError: this.removeError,
          setErrors: this.setErrors,
          clearErrors: this.clearErrors,
        }}
      >
        {children}
      </Provider>
    );
  }
}
