import { createBrowserHistory } from 'history';
import React from 'react';
import ReactGA from 'react-ga';
import { Route, Router } from 'react-router-dom';
import './App.css';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { Alerts } from './Components/Alerts';
// import { Header } from './Components/Layouts/Header';
import AnswerBar from './Components/AnswerBar';
import { AppConsumer, AppProvider } from './Components/AppContext';
import { live } from './config';
import { UserConsumer, UserProvider } from './User';
import AppUrlOpener from './AppUrlOpener';

const { StatusBar } = Plugins;
const { isNative } = Capacitor;

if (isNative) {
  StatusBar.setStyle({
    style: StatusBarStyle.Dark,
  });
}

const history = createBrowserHistory();

if (live) {
  // Google Analytics
  ReactGA.initialize('UA-138429629-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Track url changes
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}

const App = () => (
  <UserProvider>
    <UserConsumer>
      {/* eslint-disable-next-line no-unused-vars */}
      {(userContext) => (
        <AppProvider>
          <AppConsumer>
            {(appContext) => (
              <Router history={history}>
                <AppUrlOpener />
                <div>
                  {/* 
                    <UserTest userContext={userContext} />
                  */}
                  <Route render={(props) => <AnswerBar {...props} />} />
                  <Alerts
                    errors={appContext.state.errors}
                    removeError={appContext.removeError}
                    messages={appContext.state.messages}
                    removeMessage={appContext.removeMessage}
                  />
                </div>
              </Router>
            )}
          </AppConsumer>
        </AppProvider>
      )}
    </UserConsumer>
  </UserProvider>
);

export default App;
