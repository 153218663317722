import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';

export default () => (
  <div className="brand">
    <div className="logo">
      <Link to="/">
        <img src={logo} className="app-logo" alt="AnswerBar" />
      </Link>
    </div>
  </div>
);
